import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    document.title = "Dashboard - Shadi Tayari Admin"
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const [categories, setcategories] = useState(0);
    const [attributes, setattributes] = useState(0);
    const [countries, setcountries] = useState(0);
    const [cities, setcities] = useState(0);
    const [areas, setareas] = useState(0);
    const [ethnics, setethnics] = useState(0);
    const [themes, setthemes] = useState(0);
    const [religions, setreligions] = useState(0);
    const [questions, setquestions] = useState(0);
    const [users, setusers] = useState(0);
    const [blogs, setblogs] = useState(0);
    const [blogCat, setblogCat] = useState(0);
    const [Vendor, setVendor] = useState(0);
    const [Task, setTask] = useState(0);
    const [admins, setadmins] = useState(0);
    const [contact, setcontact] = useState(0);
    const [load, setload] = useState(false);
    const [load1, setload1] = useState(false);
    const [load2, setload2] = useState(false);
    const [load3, setload3] = useState(false);
    const [load4, setload4] = useState(false);
    const [load5, setload5] = useState(false);
    const [load6, setload6] = useState(false);
    const [load7, setload7] = useState(false);
    const [load8, setload8] = useState(false);
    const [load9, setload9] = useState(false);
    const [load10, setload10] = useState(false);
    const [load11, setload11] = useState(false);
    const [load12, setload12] = useState(false);
    const [load13, setload13] = useState(false);
    const [load14, setload14] = useState(false);
    const [load15, setload15] = useState(false);

  return (
    <>
    <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb wf-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
      </ol>
    </nav>
    <h2 className="font-size-28 pink-color dm-font">Dashboard</h2>
    <div className="row gy-3">
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Categories</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
             <h3 className="font-size-36 pink-color fw-bold mb-0">{categories}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/categories")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Attributes</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <h3 className="font-size-36 pink-color fw-bold mb-0">{attributes}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/attributes")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Countries</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <h3 className="font-size-36 pink-color fw-bold mb-0">{countries}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/countries")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Cities</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <h3 className="font-size-36 pink-color fw-bold mb-0">{cities}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/cities")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Areas</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
           <h3 className="font-size-36 pink-color fw-bold mb-0">{areas}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/areas")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Ethnicity</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
          <h3 className="font-size-36 pink-color fw-bold mb-0">{ethnics}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/ethnics")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Themes</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
           <h3 className="font-size-36 pink-color fw-bold mb-0">{themes}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/themes")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Religions</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
          <h3 className="font-size-36 pink-color fw-bold mb-0">{religions}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/religions")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Questions</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
          <h3 className="font-size-36 pink-color fw-bold mb-0">{questions}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/questions")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Blogs</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <h3 className="font-size-36 pink-color fw-bold mb-0">{blogs}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/blogs")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Blog Categories</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <h3 className="font-size-36 pink-color fw-bold mb-0">{blogCat}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/blog-categories")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Tasks</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <h3 className="font-size-36 pink-color fw-bold mb-0">{Task}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/tasks")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Admin Users</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <h3 className="font-size-36 pink-color fw-bold mb-0">{admins}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/admins")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Vendors</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <h3 className="font-size-36 pink-color fw-bold mb-0">{Vendor}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/vendors")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Users</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
          <h3 className="font-size-36 pink-color fw-bold mb-0">{users}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/users")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Contact Queries</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <h3 className="font-size-36 pink-color fw-bold mb-0">{contact}</h3>
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/contact-form")}><u>View All</u></p>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default Dashboard