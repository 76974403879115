import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { extraReducers } from "./apiReducer";

axios.defaults.baseURL = process.env.REACT_APP_APIURL;
const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
axios.defaults.headers.common['Authorization'] = user?.token ? `Bearer ${user.token}` : '';


const initialState = {
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  user: user,
};


export const signIn = createAsyncThunk("/auth/signin", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/auth/signin`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const addCountry = createAsyncThunk("/country/add", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/country/add`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editCountry = createAsyncThunk("/country/edit", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/country/edit`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getCountryList = createAsyncThunk("/country/all", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/country/all`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteCountry = createAsyncThunk("/country/delete", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/country/delete/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addCity = createAsyncThunk("/country/addCity", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/country/addCity`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editCity = createAsyncThunk("/country/editCity", async ({id, bodyData}, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/country/editCity/${id}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getCityList = createAsyncThunk("/country/allCities", async ({countryId}, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/country/allCities?countryId=${countryId}`,);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteCity = createAsyncThunk("/country/deleteCity", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/country/deleteCity/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addArea = createAsyncThunk("/country/addArea", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/country/addArea`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editArea = createAsyncThunk("/country/editArea", async ({id, bodyData}, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/country/editArea/${id}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getAreasList = createAsyncThunk("/country/allAreaList", async ({cityId, countryId}, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/country/allAreaList?cityId=${cityId}&countryId=${countryId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteArea = createAsyncThunk("/country/deleteArea", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/country/deleteArea/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addCategory = createAsyncThunk("/category/add", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/category/add`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editCategory = createAsyncThunk("/category/edit", async ({id,bodyData}, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/category/edit/${id}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getCategoryList = createAsyncThunk("/category/all", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/category/all?parent=${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteCategory = createAsyncThunk("/category/delete", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/category/delete/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addEthnics = createAsyncThunk("/ethnic/addEthnic", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/ethnic/addEthnic`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editEthnics = createAsyncThunk("/ethnic/editEthnic", async ({id, bodyData}, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/ethnic/editEthnic/${id}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getEthnicsList = createAsyncThunk("/ethnic/allEthnics", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/ethnic/allEthnics`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteEthnics = createAsyncThunk("/ethnic/deleteEthnic", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/ethnic/deleteEthnic/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addTheme = createAsyncThunk("/theme/addTheme", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/theme/addTheme`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editTheme = createAsyncThunk("/theme/editTheme", async ({id, bodyData}, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/theme/editTheme/${id}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getThemeList = createAsyncThunk("/theme/allThemes", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/theme/allThemes`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteTheme = createAsyncThunk("/theme/deleteTheme", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/theme/deleteTheme/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addWeddingType = createAsyncThunk("/weddingType/addWeddingType", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/weddingType/addWeddingType`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editWeddingType = createAsyncThunk("/weddingType/editWeddingType", async ({id, bodyData}, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/weddingType/editWeddingType/${id}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getWeddingTypeList = createAsyncThunk("/weddingType/allWeddingTypes", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/weddingType/allWeddingTypes`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteWeddingType = createAsyncThunk("/weddingType/deleteWeddingType", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/weddingType/deleteWeddingType/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addBusinessAttribute = createAsyncThunk("/businessAttribute/addBusinessAttribute", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/businessAttribute/addBusinessAttribute`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editBusinessAttribute = createAsyncThunk("/businessAttribute/editBusinessAttribute", async ({id, bodyData}, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/businessAttribute/editBusinessAttribute/${id}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getBusinessAttributeList = createAsyncThunk("/businessAttribute/allBusinessAttributes", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/businessAttribute/allBusinessAttributes`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteBusinessAttribute = createAsyncThunk("/businessAttribute/deleteBusinessAttribute", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/businessAttribute/deleteWeddingType/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addAttribute = createAsyncThunk("/attribute/add", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/attribute/add`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editAttribute = createAsyncThunk("/attribute/edit", async ({id, bodyData}, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/attribute/edit/${id}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getAttributeList = createAsyncThunk("/attribute/allAttributes", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/attribute/allAttributes?parent=${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteAttribute = createAsyncThunk("/attribute/delete", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/attribute/delete/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addReligion = createAsyncThunk("/religion/addReligion", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/religion/addReligion`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editReligion = createAsyncThunk("/religion/editReligion", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/religion/editReligion`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getReligionList = createAsyncThunk("/religion/allReligion", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/religion/allReligion`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteReligion = createAsyncThunk("/religion/deleteReligion", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/religion/deleteReligion/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addQuestion = createAsyncThunk("/question/add", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/question/add`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editQuestion = createAsyncThunk("/question/edit", async ({bodyData, id}, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/question/edit/${id}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getQuestionList = createAsyncThunk("/question/all", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/question/all`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteQuestion = createAsyncThunk("/question/delete", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/question/delete/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const addAdmin = createAsyncThunk("/user/addAdmin", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/user/addAdmin`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editAdmin = createAsyncThunk("/user/editAdmin", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/user/editAdmin`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteAdmin = createAsyncThunk("/admin/delete", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/delete/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getUserList = createAsyncThunk("/user/list", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/user/list?type=${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const deleteUser = createAsyncThunk("/user/delete", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/user/delete/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const apiSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
      token: (state) => {
        var user = JSON.parse(localStorage.getItem("user"));
        if (user) {
          state.user = user?.token;
        }
      },
    },
    extraReducers,
  });
  export const { token } = apiSlice.actions;
  export const getProfile = (state) => state?.users?.user;
  export const getUsertoken = (state) => state?.users?.user?.token;

  
    
  export default apiSlice.reducer;