import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { addAdmin, editAdmin } from '../store/apiSlice';
import Spinner from '../components/Spinner';
import { deviceTypes, userTypes } from '../utils/Helper';

const AddAdmin = () => {
  document.title = "Add Admin - Shadi Tayari Admin"
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const arr=["read","write","delete"]

    const [load, setload] = useState(false);
    const [isSecureEntry, setisSecureEntry] = useState(true)
    const [id, setId] = useState("");
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [imageName, setimageName] = useState("");
    const [stores_access, setstores_access] = useState([]);
    const [blogs_access, setblogs_access] = useState([]);
    const [users_access, setusers_access] = useState([]);
    const [systemconfig_access, setsystemconfig_access] = useState([]);


    useEffect(() => {
      if(location?.state?._id){
        setId(location?.state?._id?._id)
        setname(location?.state?._id?.name)
        setemail(location?.state?._id?.email)
        setimageName(location?.state?._id?.imageName)
        setstores_access(location?.state?._id?.stores_access)
        setblogs_access(location?.state?._id?.blogs_access)
        setusers_access(location?.state?._id?.users_access)
        setsystemconfig_access(location?.state?._id?.systemconfig_access)
      }
      else{
        setId("")
        setname("")
        setemail("")
        setimageName("")
        setstores_access([])
        setblogs_access([])
        setusers_access([])
        setsystemconfig_access([])
      }
    }, []);

    const handleCheckboxChange = (event) => {
      const value = event.target.value;
      const isChecked = event.target.checked;
      setstores_access((prevStoresAccess) => {
        if (isChecked) {
          if (!prevStoresAccess.includes(value)) {
            return [...prevStoresAccess, value];
          }
        } else {
          return prevStoresAccess.filter((item) => item !== value);
        }
        return prevStoresAccess; 
      });
    };

    const handleCheckboxChange1 = (event) => {
      const value = event.target.value;
      const isChecked = event.target.checked;
      setblogs_access((prevStoresAccess) => {
        if (isChecked) {
          if (!prevStoresAccess.includes(value)) {
            return [...prevStoresAccess, value];
          }
        } else {
          return prevStoresAccess.filter((item) => item !== value);
        }
        return prevStoresAccess; 
      });
    };

    const handleCheckboxChange2 = (event) => {
      const value = event.target.value;
      const isChecked = event.target.checked;
      setusers_access((prevStoresAccess) => {
        if (isChecked) {
          if (!prevStoresAccess.includes(value)) {
            return [...prevStoresAccess, value];
          }
        } else {
          return prevStoresAccess.filter((item) => item !== value);
        }
        return prevStoresAccess; 
      });
    };

    const handleCheckboxChange3 = (event) => {
      const value = event.target.value;
      const isChecked = event.target.checked;
      setsystemconfig_access((prevStoresAccess) => {
        if (isChecked) {
          if (!prevStoresAccess.includes(value)) {
            return [...prevStoresAccess, value];
          }
        } else {
          return prevStoresAccess.filter((item) => item !== value);
        }
        return prevStoresAccess; 
      });
    };
      
    const addandEditSubAdmin = async (e) => {
      e.preventDefault();
      try {
        setload(true)
        const formData= new FormData();
        formData.append("id", id)
        formData.append("name", name)
        formData.append("email", email)
        formData.append("phone", "+92 336 9829274")
        formData.append("password", password)
        formData.append("profile", imageName)
        formData.append("userType", userTypes.subAdministrator)
        formData.append("deviceType", deviceTypes.web)
        formData.append("platform", "shadiTayari")
        formData.append("storeAccess", JSON.stringify(stores_access))
        formData.append("blogAccess", JSON.stringify(blogs_access))
        formData.append("usersAccess", JSON.stringify(users_access))
        formData.append("systemAccess", JSON.stringify(systemconfig_access))
        if(id){        
          await dispatch(editAdmin(formData)).unwrap();          
        } else { 
          await dispatch(addAdmin(formData)).unwrap();                 
        }
        navigate("/admins")
        setload(false)
      } catch (rejectedValueOrSerializedError) {
        setload(false)
        console.log(rejectedValueOrSerializedError);
      }
    };
  return (
    <>
    {load && <Spinner />}
    <div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Admin</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Admin User</li>
        </ol>
    </nav>
    <h2 className="font-size-28 pink-color dm-font mb-3">Add Admin User</h2>
    <div className="radius-20 white-bg wf-shadow p-3 mb-4">
      <div className="row gy-3">
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="text"  class="form-control" id="Name" placeholder="Name" value={name} onChange={(e)=>setname(e.target.value)}/>
      <label for="Name">Name</label>
    </div>
      </div>
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="email"  class="form-control" id="Email" placeholder="Email" required value={email} onChange={(e)=>setemail(e.target.value)}/>
      <label for="Email">Email</label>
    </div>
      </div>
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input onChange={(e) => setpassword(e.target.value)} type={isSecureEntry ? "password" : "text"}  class="form-control" id="Password" placeholder="Password" />
      <i onClick={() => setisSecureEntry((prev) => !prev)} className={`bi ${isSecureEntry ? "bi-eye-fill" : "bi-eye-slash-fill"} eye-password`}></i>
      <label for="Password">Password</label>
    </div>
      </div>
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="file" onChange={(e)=> setimageName(e.target.files[0])} class="form-control" id="Profile-Image" placeholder="Profile Image" />
      <label for="Profile-Image">Profile Image</label>
    </div>
      </div>
      <div className="col-md-12">
      <p class="font-size-20 pink-color dm-font mb-2">Vendor Store Access</p>
        <div className="d-flex flex-wrap gap-2 mb-3">
        {arr?arr.map((e)=>(
        <>
          <input class="btn-check" name="store-access" value={e} checked={stores_access.includes(e)} onChange={handleCheckboxChange} type="checkbox" id={`${e}-vendor`}/>
          <label class="btn wf-select-btn" for={`${e}-vendor`}>{" "}{e.charAt(0).toUpperCase() + e.slice(1)}</label>
        </>
        )):null}
        </div>

        <p class="font-size-20 pink-color dm-font mb-2">Blogs Access</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
          {arr?arr.map((e)=>(
        <>
          <input class="btn-check" name="blog-access3" value={e} checked={blogs_access.includes(e)} onChange={handleCheckboxChange1} type="checkbox" id={`${e}-blog`}/>
          <label class="btn wf-select-btn" for={`${e}-blog`}>{" "}{e.charAt(0).toUpperCase() + e.slice(1)}</label>
        </>
        )):null}
        </div>

        <p class="font-size-20 pink-color dm-font mb-2">Users Access</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
          {arr?arr.map((e)=>(
        <>
          <input class="btn-check" name="user-access1" value={e} checked={users_access.includes(e)} onChange={handleCheckboxChange2} type="checkbox" id={`${e}-user`}/>
          <label class="btn wf-select-btn" for={`${e}-user`}>{" "}{e.charAt(0).toUpperCase() + e.slice(1)}</label>
        </>
        )):null}
        </div>

        <p class="font-size-20 pink-color dm-font mb-2">System Configuration Access</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
          {arr?arr.map((e)=>(
        <>
          <input class="btn-check" name="sistem-access2" value={e} checked={systemconfig_access.includes(e)} onChange={handleCheckboxChange3} type="checkbox" id={`${e}-system`}/>
          <label class="btn wf-select-btn" for={`${e}-system`}>{" "}{e.charAt(0).toUpperCase() + e.slice(1)}</label>
        </>
        )):null}
        </div>

      </div>
      </div>
    </div>

    <div class="d-flex justify-content-center gap-2">
      <button onClick={()=>navigate("/admin")} class="btn wf-btn wf-black-btn py-2 px-4">Cancel</button>
      <button onClick={addandEditSubAdmin} class="btn wf-btn wf-pink-btn py-2 px-4" >Create Account</button>
    </div>
    </div>

    </>
  )
}

export default AddAdmin