import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addArea, deleteArea, editArea, getAreasList, getCityList, getProfile } from '../store/apiSlice';
import { Pencil, Trash2,} from "lucide-react";
import SearchDropdownFilter from '../components/elements/SearchDropdownFilter';
import FloatingInput from '../components/elements/FloatingInput';
import Title from '../components/elements/Title';
import Breadcrumb from '../components/elements/Breadcrumb';
import Button from '../components/elements/Button';
import CustomModal from '../components/elements/Modal';
import ModalContent from '../components/elements/ModalContent';
import DynamicSelect from '../components/elements/DynamicSelect';
import DeleteModal from '../components/DeleteModal';
import Table from '../components/elements/Table';
import { useLocation } from 'react-router-dom';

const Areas = () => {
  document.title = "Areas - Shadi Tayari Admin"
    const profile = useSelector(getProfile)
    const location = useLocation()
    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const [cities, setcities] = useState(null);
    const [areas, setareas] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
  
    const [cityId, setcityId] = useState("");
    const [filterCityId, setfilterCityId] = useState("");
    const [filterCountryId, setfilterCountryId] = useState("66596fcc9079984b6b40ad6e");
    const [area, setarea] = useState("");
    const [toggle, settoggle] = useState(false);
    const [load, setload] = useState(false);
  
  
    const viewModal = async (item, type) => {
      setIsOpen(true);
      if (type === "add") {
        setId();
        setarea();
        setcityId();
      } else if (type === "edit") {
        setarea(item?.name);
        setcityId(item?.cityId?._id)
        setId(item?._id);
      } else if (type === "delete") {
        setId(item);
      }
      setModalType(type);
      setIsOpen(true);
    };

    function closeModal() {
      setIsOpen(false);
      setId("")
      setarea("")
      setcityId("")
    }
  
    const areaDelete = async (id) => {
      try {
        await dispatch(deleteArea(id)).unwrap();
        setIsOpen(false);
        setFilteredData((prev) => prev?.filter(item => item?._id !== id));
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    const Areas = async () => {
      try {
        setload(true);
        setareas(null);
        setFilteredData(null);
        const response = await dispatch(getAreasList({cityId: filterCityId, countryId: filterCountryId})).unwrap();
        setareas(response?.data);
        setFilteredData(response?.data);
        setload(false);
    } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    const Cities = async () => {
      try {
        setcities(null);
        const response = await dispatch(getCityList({countryId: filterCountryId})).unwrap();
        setcities(response?.data);
    } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    useEffect(() => {
      Cities();
    }, []);
  
    useEffect(() => {
      Areas();
    }, [filterCityId, filterCountryId, location]);
  
    const addandEditArea = async (e) => {
      e.preventDefault();
      try {
        const payload = {
          id: id, 
          name: area, 
          cityId: cityId,
          countryId: filterCountryId
        }
        if (id) {
          await dispatch(editArea(payload)).unwrap();
        } else { 
          await dispatch(addArea(payload)).unwrap();
        }
        setIsOpen(false);
        Areas();
        setcityId("")
        setarea("")
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
    
    const [parentFilter, setparentFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
  
    const applyFilter = () => {
      const filteredResult = areas?.filter(item => {
        const userName = item?.cityId?.name.toLowerCase();
        return (
          (parentFilter === '' || userName.includes(parentFilter.toLowerCase()))
        );
      });
  
      setFilteredData(filteredResult);
      settoggle(false);
    };

  
    const resetFilter = () => {
      setparentFilter('');
      setFilteredData(areas)
      settoggle(false);
    };

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
      {(modalType === "add" || modalType === "edit") ? 
      <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} Area`} closeModal={closeModal} onClick={addandEditArea} buttonText={modalType === "add" ? "Submit" : "Update"}>
        <FloatingInput value={area} onChange={(e) => setarea(e.target.value)} placeholder="Area Name" bottomSpace={3} />
        <DynamicSelect value={cityId} onChange={(e)=> setcityId(e.target.value)} optionLabel='name' optionValue='_id' options={cities} placeholder="Select City" />
      </ModalContent>
      : modalType === "delete" ? 
      <DeleteModal closeModal={closeModal} onClick={() => areaDelete(id)} label="Area" />
      : null}
    </CustomModal>

    <div className="wf-area-list-page px-md-5 pt-3 px-3 pb-5">
    <Breadcrumb items={[{ label: 'Menu',}, { label: 'System Config',},{ label: 'Areas', active: true }]}/>
      <div className="row gy-3 mb-4 align-items-center mb-2">
        <div className="col-md-3 col-6 align-self-center order-1">
          <Title>Areas</Title>
        </div>
        <div className="col-md-6 order-md-2 order-3">
          <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter} toggleCheck={toggle}>
            <FloatingInput onChange={e => setparentFilter(e.target.value)} value={parentFilter} placeholder="Search by City" />
          </SearchDropdownFilter>
        </div>
        <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
          <Button variant="purple" size="small" onClick={() => viewModal(null, "add")}>Add Area</Button>
        </div>
      </div>
      <div className="radius-20 white-bg wf-shadow p-3">
        <p className="font-size-20 pink-color">Areas: {areas?.length}</p>
        <Table>
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Area Name</th>
            <th scope="col">City</th>
            <th scope="col">Country</th>
            <th scope="col">Created By</th>
            <th style={{width:'100px'}} scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item,i)=> (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{item?.name}</td>
            <td>{item?.cityId?.name}</td>
            <td>{item?.cityId?.countryId?.name}</td>
            <td>{item?.adminId?.name}</td>
            <td>
              <div className="d-flex align-items-center gap-2">
                  <div onClick={() => viewModal(item, "edit")} className={`wf-action-icons`}><Pencil width="18" /></div>
                  <div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons`}><Trash2 width="18" /></div>
              </div>
            </td>
            </tr>))}
        </tbody>
        </Table>
      </div>
    </div>
    </>
  )
}

export default Areas