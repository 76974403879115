import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addTheme, deleteTheme, editTheme, getProfile, getThemeList } from '../store/apiSlice';
import { Pencil, Trash2 } from "lucide-react";
import CustomModal from '../components/elements/Modal';
import ModalContent from '../components/elements/ModalContent';
import FloatingInput from '../components/elements/FloatingInput';
import DeleteModal from '../components/DeleteModal';
import Breadcrumb from '../components/elements/Breadcrumb';
import Title from '../components/elements/Title';
import Button from '../components/elements/Button';
import Table from '../components/elements/Table';

const Themes = () => {
  document.title = "Themes - Shadi Tayari Admin"
  const profile = useSelector(getProfile)
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [themes, setthemes] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [name, setname] = useState("");
  const [image, setimage] = useState("");
  const [load, setload] = useState(false);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    if (type === "add") {
      setId();
      setname();
    } else if (type === "edit") {
      setname(item?.name);
      setimage(item?.image);
      setId(item?._id);
    } else if (type === "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const themeDelete = async (id) => {
    try {
      await dispatch(deleteTheme(id)).unwrap();
      setIsOpen(false);
      Themes();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Themes = async () => {
    try {
      setload(true);
      setthemes(null);
      const response = await dispatch(getThemeList()).unwrap();
      setthemes(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Themes();
  }, []);

  const addandEditTheme = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await dispatch(editTheme({id:id,name: name})).unwrap();
        setIsOpen(false);
        Themes();
        setId("")     
      } else { 
        await dispatch(addTheme({name: name})).unwrap();
        setIsOpen(false);
        Themes();
        setname("")
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  return (
    <>
    <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
      {(modalType === "add" || modalType === "edit") ? 
      <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} Theme`} closeModal={closeModal} onClick={addandEditTheme} buttonText={modalType === "add" ? "Submit" : "Update"}>
        <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Theme Name" bottomSpace={3} />
        <FloatingInput type="file" onChange={(e) => setimage(e.target.files[0])} placeholder="Theme Icon" />
      </ModalContent>
      : modalType === "delete" ? 
      <DeleteModal closeModal={closeModal} onClick={() => themeDelete(id)} label="Theme" />
      : null}
    </CustomModal>


    <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
    <Breadcrumb items={[{ label: 'Menu',}, { label: 'System Config',},{ label: 'Themes', active: true }]}/>
      <div className="row gy-3 mb-4 align-items-center mb-2">
        <div className="col-md-3 col-6 align-self-center order-1">
          <Title>Themes</Title>
        </div>
        <div className="col-md-6 order-md-2 order-3">
        </div>
        <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
          <Button variant="purple" size="small" onClick={() => viewModal(null, "add")}>Add Theme</Button>
        </div>
      </div>
      <div className="radius-20 white-bg wf-shadow p-3">
        <p className="font-size-20 pink-color">Themes: {themes?.length}</p>
        <Table>
          <thead>
          <tr>
          <th scope="col">S.No</th>
          <th scope="col">Name</th>
          <th scope="col">Created By</th>
          <th style={{width:'100px'}} scope="col">Action</th>
          </tr>
          </thead>
          <tbody>
          {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={6}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : themes?.map((item,i)=> (
            <tr key={i}>
              <td>{i+1}</td>
              <td>{item?.name}</td>
              <td>{item?.user_id?.name}</td>
              <td>
                <div className="d-flex align-items-center gap-2">
                    <div onClick={() => viewModal(item, "edit")} className={`wf-action-icons`}><Pencil width="18" /></div>
                    <div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons`}><Trash2 width="18" /></div>
                </div>
              </td>
              </tr>))}
          </tbody>
        </Table>
      </div>
    </div>
    </>
  )
}

export default Themes