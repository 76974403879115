import React, { useEffect, useState } from 'react'
import { ChevronDown } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '../store/apiSlice';
import ChangePassword from './ChangePassword';
import CustomModal from './elements/Modal';

const Navbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const profile = useSelector(getProfile)  
    const [modalIsOpen, setIsOpen] = useState(false);
  
    function openModal() {
      setIsOpen(true);
    }
    function closeModal() {
      setIsOpen(false);
    }
     
    const handleLogout=async()=>{
      try {
        await dispatch().unwrap()
        navigate("/")
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError)
      }
    }
  
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        const scrolled = window.scrollY > 10;
        setIsScrolled(scrolled);
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (
    <>
    <header className={`${isScrolled ? 'wf-navbar white-bg' : 'wf-navbar'}`}>
      <div className="row px-2 gy-4">
        <div className="col-md-6 d-md-flex align-items-center gap-5">
        <img onClick={() => navigate("/")} className="wf-logo cursor-pointer" width="280" src={`../assets/images/logo.svg`} />
          <p className="font-size-24 pink-color mb-0 fw-medium dm-font">Greetings, <span className="wf-text-highlight-navbar">{profile?.name}</span></p>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-md-end justify-content-center" style={{ "gap": "25px" }}>
          <img class="" alt="messages" src="../assets/images/notification-icon.svg" />
          <div class="d-flex align-items-center gap-3 wf-profile-img">
            <img class="rounded-circle wf-shadow" alt="avatar" src={profile?.imageName ? `${process.env.REACT_APP_IMGURL}${profile?.imageName}` : "https://st.digitalbee.studio/wp-content/uploads/DP.jpg"} />
            <p className="font-size-16 pink-color mb-0 fw-semibold">{profile?.name}</p>
          </div>
          <div class="dropdown wf-nav-dropdown-link">
            <a class="pink-color" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <ChevronDown />
            </a>
            <ul class="dropdown-menu">
              <li><a onClick={openModal} class="dropdown-item">Change Password</a></li>
              <li><a onClick={handleLogout} class="dropdown-item">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </header>

    <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}><ChangePassword closeModal={closeModal} /></CustomModal>
    </>
  )
}

export default Navbar