import React, { useEffect, useState } from 'react'
import { Pencil, Trash2, } from "lucide-react";
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/elements/Button';
import Title from '../components/elements/Title';
import Breadcrumb from '../components/elements/Breadcrumb';
import DeleteModal from '../components/DeleteModal';
import CustomModal from '../components/elements/Modal';
import ModalContent from '../components/elements/ModalContent';
import FloatingInput from '../components/elements/FloatingInput';
import Table from '../components/elements/Table';
import { addCountry, deleteCountry, editCountry, getCountryList } from '../store/apiSlice';

const Countries = () => {
  document.title = "Countries - Shadi Tayari Admin"
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [countries, setcountries] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [name, setname] = useState("");
  const [currency, setcurrency] = useState("");
  const [flag, setflag] = useState("");
  const [load, setload] = useState(false);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    if (type === "add") {
      setId();
      setname();
      setflag();
    } else if (type === "edit") {
      setId(item?._id);
      setname(item?.name)
      setflag(item?.flag)
    } else if (type === "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const countryDelete = async (id) => {
    try {
      await dispatch(deleteCountry(id)).unwrap();
      setIsOpen(false);
      Countries();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const Countries = async () => {
    try {
      setload(true);
      setcountries(null);
      const response = await dispatch(getCountryList()).unwrap();
      setcountries(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Countries();
  }, []);

  const addandEditCountry = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", name);
      data.append("flag", flag);
      data.append("currency", currency);
      if (id) {
        await dispatch(editCountry(data)).unwrap();
      } else { 
        await dispatch(addCountry(data)).unwrap();
      }
      setIsOpen(false);
      Countries();
      setId("")     
      setname("")     
      setflag("")     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  return (
    <>
    <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
      {(modalType === "add" || modalType === "edit") ? 
      <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} Country`} closeModal={closeModal} onClick={addandEditCountry} buttonText={modalType === "add" ? "Submit" : "Update"}>
        <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Country Name" bottomSpace={3} />
        <FloatingInput value={currency} onChange={(e) => setcurrency(e.target.value)} placeholder="Country Currency" bottomSpace={3} />
        <FloatingInput type="file" onChange={(e) => setflag(e.target.files[0])} placeholder="Country Flag" />
      </ModalContent>
      : modalType === "delete" ? 
      <DeleteModal closeModal={closeModal} onClick={() => countryDelete(id)} label="Country" />
      : null}
    </CustomModal>


    <div className="wf-city-list-page px-md-5 pt-3 px-3 pb-5">
    <Breadcrumb items={[{ label: 'Menu',}, { label: 'System Config',},{ label: 'Countries', active: true }]}/>
      <div className="row gy-3 mb-4 align-items-center mb-2">
        <div className="col-md-3 col-6 align-self-center order-1">
          <Title>Countries</Title>
        </div>
        <div className="col-md-6 order-md-2 order-3">
        </div>
        <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
          <Button variant="purple" size="small" onClick={() => viewModal(null, "add")}>Add Country</Button>
        </div>
      </div>
      <div className="radius-20 white-bg wf-shadow p-3">
        <p className="font-size-20 pink-color">Countries: {countries?.length}</p>
        <Table>
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Country Name</th>
            <th scope="col">Country Flag</th>
            <th style={{width:'100px'}} scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : countries?.map((item,i)=> (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{item?.name}</td>
            <td><img src={`${process.env.REACT_APP_IMGURL}${item?.flag}`} width={35} alt="" /></td>
            <td>
              <div className="d-flex align-items-center gap-2">
                  <div onClick={() => viewModal(item, "edit")} className={`wf-action-icons`}><Pencil width="18" /></div>
                  <div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons`}><Trash2 width="18" /></div>
              </div>
            </td>
            </tr>))}
        </tbody>
        </Table>
      </div>
    </div>
    </>
  )
}

export default Countries