export const userTypes = {
    administrator: 'administrator',
    appUser: 'appUser',
    guest: 'guest',
    subAdministrator: 'subAdministrator',
    planner: 'planner',
    vendor: 'vendor',
};
export const deviceTypes = {
    android: 'android',
    ios: 'ios',
    web:"web"
};
export const Messages={
    chatMinUsers:'A chat room must have at least two users.',
    userNotFound:'User not found.',
    passwordMismatch:'Passwords do not match.',
    invalidCredentials:'Invalid credentials.',
    invalidToken:'Invalid token.',
    roomNotFound:'Room not found.',
    roomCreated:"You can not message each other"
}
export const otpTypes = {
  registration: 'registration',
  forgotPassword: 'forgotPassword',
}
export const otpMethods = {
  sms: 'sms',
  email: 'email',
  whatsapp: 'whatsapp',
}
export const environments = {
    staging: 'staging',
    local: 'local',
    production: 'production',
};
export const uploadPictureTypes = {
    group: 'group',
    profile: 'profile-picture',
};
export const chatTypes={
    group:'group',
    private:'private'
}
export const messageTypes={
    text:'text',
    image:'image',
    video:'video',
    info:'info',
}