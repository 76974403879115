import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import { Search, Trash2, X } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getProfile, getUserList } from '../store/apiSlice';
import moment from 'moment';
import { userTypes } from '../utils/Helper';
import Button from '../components/elements/Button';
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");

const Users = () => {
  
const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector(getProfile)
  const [id, setId] = useState("");
  const [type, settype] = useState("");
  const [title, settitle] = useState("");
  const [users, setusers] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const userDelete = async (id) => {
    try {
      await dispatch(deleteUser({id: id})).unwrap();
      setIsOpen(false);
      Users();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Users = async () => {
    try {
      setload(true);
      setusers(null);
      setFilteredData(null);
      const response = await dispatch(getUserList(type)).unwrap();
      setusers(response?.data);
      setFilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (location.pathname === "/admins") {
      settype(userTypes.subAdministrator);
      settitle("Admins")
    } else if (location.pathname === "/customers") {
      settype(userTypes.appUser);
      settitle("Customers")
    } else if (location.pathname === "/planners") {
      settype(userTypes.planner);
      settitle("Planners")
    } else if (location.pathname === "/vendors") {
      settype(userTypes.vendor);
      settitle("Vendors")
    }
  }, [location.pathname]);

  useEffect(() => {
    if (type !== "") {
      Users();
    }
  }, [type]);


  const [parentFilter, setparentFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = users?.filter(item => {
      const userName = item?.email?.toLowerCase();
      const creatorName = item?.role?.toLowerCase()
      return (
        (parentFilter === '' || userName.includes(parentFilter?.toLowerCase())) &&
        (selectedUsers?.length === 0 || selectedUsers?.includes(creatorName?.toLowerCase()))
      );
    });

    setFilteredData(filteredResult);
    navigate(`${location.pathname}`);
    settoggle(false);
  };

  const handleStatusChange = (status) => {
    if (selectedUsers.includes(status)) {
      setselectedUsers(prevStatuses => prevStatuses.filter(s => s !== status));
    } else {
      setselectedUsers(prevStatuses => [...prevStatuses, status]);
    }
  };

  const resetFilter = () => {
    setparentFilter('');
    setselectedUsers([]);
    setFilteredData(users)
    settoggle(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredData?.length);
  const currentData = filteredData?.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);

  document.title = `${title} - Shadi Tayari Admin`


  return (
    <>
  {profile?.systemconfig_access?.includes("write")&&(
  <Modal closeTimeoutMS={500} isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} className={`modal fade show wf-modal wf-modal-30`}>
    <div class="modal-dialog modal-dialog-centered wf-modal-dialog">
    {modalType == "delete" ? ( 
      <div class="modal-content">
        <div class="modal-header border-0 pb-0">
          <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete User</h1>
          <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mt-0">
          <p class="font-size-14 color-black mb-0">Are you sure you want to delete this User?</p>
        </div>
        <div class="modal-footer border-0 justify-content-center gap-2">
          <button onClick={closeModal} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
          <button onClick={() => userDelete(id)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Delete</button>
        </div>
      </div>
      ) : null}
    </div>
  </Modal>)}

<div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb wf-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item"><a>Users</a></li>
        <li class="breadcrumb-item active" aria-current="page">{title}</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">{title}</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown wf-search-dropdown">
        <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label font-size-20 pink-color dm-font">Email</label>
              <div class="col-sm-10">
                <input type="text" onChange={e => setparentFilter(e.target.value)} value={parentFilter} placeholder="Filter by Email" class="form-control wf-shadow border-0 py-3 font-size-14" id="inputname" />
              </div>
            </div>
            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label font-size-20 pink-color dm-font">Role</label>
              <div class="col-sm-10 d-flex gap-2 flex-wrap">
                  <input type="checkbox" class="btn-check" checked={selectedUsers.includes('vendor')} onChange={() => handleStatusChange('vendor')} id={`vendor`} value="vendor" />
                  <label class="btn wf-select-btn" for={`vendor`}>Vendor</label>

                  <input type="checkbox" class="btn-check" checked={selectedUsers.includes('customer')} onChange={() => handleStatusChange('customer')} id={`customer`} value="customer" />
                  <label class="btn wf-select-btn" for={`customer`}>Customer</label>

              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={applyFilter}>Search</button>
              <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        {type === userTypes.subAdministrator && <Button variant="purple" size="small" onClick={()=> navigate("/add-admin")}>Add Admin</Button>}
      </div>
    </div>
    <div className="radius-20 white-bg wf-shadow p-3">
      <p className="font-size-20 pink-color">{title} : {filteredData?.length}</p>
      <div class="table-responsive">
        <table class="table wf-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th style={{ width: '130px' }} scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : currentData?.map((item, i) => (
              <tr key={i}>
                <td>{i + startIndex + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.email?.substring(0,30)}</td>
                <td>{item?.role}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    {profile?.systemconfig_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons`}><Trash2 width="18" /></div>)}
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
  {/* {filteredData?.length > 50 &&
      } */}
    </div>
  </div>

  
    </>
  )
}

export default Users