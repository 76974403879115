import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from '../pages/Login';
import { useSelector } from 'react-redux';
import { getUsertoken } from '../store/apiSlice';
import Areas from '../pages/Areas';
import Categories from '../pages/Categories';
import Dashboard from '../pages/Dashboard';
import Navbar from './Navbar';
import SideBar from './SideBar';
import Cities from '../pages/Cities';
import Ethnics from '../pages/Ethnics';
import Attributes from '../pages/Attributes';
import Themes from '../pages/Themes';
import Religions from '../pages/Religions';
import AddAdmin from '../pages/AddAdmin';
import Users from '../pages/Users';
import Countries from '../pages/Countries';
import WeddingTypes from '../pages/WeddingTypes';
import BusinessAttributes from '../pages/BusinessAttributes';
import Question from '../pages/Question';
import AddQuestion from '../pages/AddQuestion';

const Layout = () => {
  const authtoken = useSelector(getUsertoken);
  return (
    <>
    <BrowserRouter>
        <Content authtoken={authtoken} />
    </BrowserRouter>
    </>
  )
}

const Content = ({ authtoken }) => {
  useEffect(() => {
    const body = document.body;
    if (authtoken) {
      body.classList.add('bg-image');
    } else {
      body.classList.remove('bg-image');
    }
    return () => {
      body.classList.remove('bg-image');
    };
  }, [authtoken]);

    return (
      <>
      {authtoken ? <>
      <SideBar/>
      <Navbar />
      <section className="content-section">
        <Routes>
            <Route path="/" exact element={<Dashboard />} />
            <Route path="*" exact element={<Dashboard />} />
            <Route path="/categories" exact element={<Categories />} />
            <Route path="/attributes" exact element={<Attributes />} />
            <Route path="/areas" exact element={<Areas />} />
            <Route path="/cities" exact element={<Cities />} />
            <Route path="/countries" exact element={<Countries />} />
            <Route path="/ethnics" exact element={<Ethnics />} />
            <Route path="/themes" exact element={<Themes />} />
            <Route path="/religions" exact element={<Religions />} />
            <Route path="/wedding-types" exact element={<WeddingTypes />} />
            <Route path="/business-attributes" exact element={<BusinessAttributes />} />
            <Route path="/add-admin" exact element={<AddAdmin />} />
            <Route path="/admins" exact element={<Users />} />
            <Route path="/customers" exact element={<Users />} />
            <Route path="/planners" exact element={<Users />} />
            <Route path="/vendors" exact element={<Users />} />
            <Route path="/questions" exact element={<Question />} />
            <Route path="/add-question" exact element={<AddQuestion />} />
            <Route path="/edit-question" exact element={<AddQuestion />} />
        </Routes>
      </section>
      </> : <>
        <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="*" exact element={<Login />} />
        </Routes>
      </>}
      </>
    );
  };

export default Layout