import React, { useEffect, useState } from 'react'
import { Pencil, Trash2, } from "lucide-react";
import { addCity, deleteCity, editCity, getCityList, getCountryList, getProfile } from '../store/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/elements/Button';
import Title from '../components/elements/Title';
import Breadcrumb from '../components/elements/Breadcrumb';
import DeleteModal from '../components/DeleteModal';
import CustomModal from '../components/elements/Modal';
import ModalContent from '../components/elements/ModalContent';
import FloatingInput from '../components/elements/FloatingInput';
import DynamicSelect from '../components/elements/DynamicSelect';
import Table from '../components/elements/Table';
import { useLocation } from 'react-router-dom';

const Cities = () => {
  document.title = "Cities - Shadi Tayari Admin"
  const dispatch = useDispatch();
  const profile = useSelector(state => state.users.user);
  const location = useLocation()
  const [id, setId] = useState("");
  const [cities, setcities] = useState(null);
  const [countries, setcountries] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [image, setimage] = useState("");
  const [load, setload] = useState(false);
  const [filterCountryId, setfilterCountryId] = useState("66596fcc9079984b6b40ad6e");

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    if (type === "add") {
      setId("");
      setcity("");
      setcountry("");
      setimage("");
    } else if (type === "edit") {
      setId(item?._id);
      setcity(item?.name)
      setcountry(item?.countryId?._id)
    } else if (type === "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const cityDelete = async (id) => {
    try {
      await dispatch(deleteCity(id)).unwrap();
      setIsOpen(false);
      Cities();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Cities = async () => {
    try {
      setload(true);
      setcities(null);
      const response = await dispatch(getCityList({countryId: filterCountryId})).unwrap();
      setcities(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getCountryList()).unwrap();
      setcountries(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Countries();
  }, []);

  useEffect(() => {
    Cities();
  }, [filterCountryId, location]);

  const addandEditCity = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", city);
      data.append("countryId", country);
      data.append("image", image);
      if (id) {
        await dispatch(editCity(data)).unwrap();
        setIsOpen(false);
        Cities();
        setId("")     
      } else { 
        await dispatch(addCity(data)).unwrap();
        setIsOpen(false);
        Cities();
        setcity("")
        setcountry("")
        setimage("")
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  return (
    <>
    <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
      {(modalType === "add" || modalType === "edit") ? 
      <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} City`} closeModal={closeModal} onClick={addandEditCity} buttonText={modalType === "add" ? "Submit" : "Update"}>
        <FloatingInput value={city} onChange={(e) => setcity(e.target.value)} placeholder="City Name" bottomSpace={3} />
        <FloatingInput type="file" onChange={(e) => setimage(e.target.files[0])} placeholder="City Banner" bottomSpace={3} />
        <DynamicSelect value={country} onChange={(e)=> setcountry(e.target.value)} optionLabel='name' optionValue='_id' options={countries} placeholder="Select Country" />
      </ModalContent>
      : modalType === "delete" ? 
      <DeleteModal closeModal={closeModal} onClick={() => cityDelete(id)} label="City" />
      : null}
    </CustomModal>


    <div className="wf-city-list-page px-md-5 pt-3 px-3 pb-5">
    <Breadcrumb items={[{ label: 'Menu',}, { label: 'System Config',},{ label: 'Cities', active: true }]}/>
      <div className="row gy-3 mb-4 align-items-center mb-2">
        <div className="col-md-3 col-6 align-self-center order-1">
          <Title>Cities</Title>
        </div>
        <div className="col-md-6 order-md-2 order-3">
        </div>
        <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
          <Button variant="purple" size="small" onClick={() => viewModal(null, "add")}>Add City</Button>
        </div>
      </div>
      <div className="radius-20 white-bg wf-shadow p-3">
        <p className="font-size-20 pink-color">Cities: {cities?.length}</p>
        <Table>
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">City Name</th>
            <th scope="col">Country</th>
            <th style={{width:'100px'}} scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : cities?.map((item,i)=> (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{item?.name}</td>
            <td>{item?.countryId?.name}</td>
            <td>
              <div className="d-flex align-items-center gap-2">
                  <div onClick={() => viewModal(item, "edit")} className={`wf-action-icons`}><Pencil width="18" /></div>
                  <div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons`}><Trash2 width="18" /></div>
              </div>
            </td>
            </tr>))}
        </tbody>
        </Table>
      </div>
    </div>
    </>
  )
}

export default Cities