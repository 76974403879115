import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getProfile } from '../store/apiSlice';
import { AlignJustify, ChevronDown, ChevronUp } from 'lucide-react';

const SideBar = () => {
  const location = useLocation();
  const profile = useSelector(getProfile)  
  const { pathname } = location;
  const [toggleButton, SetToggleButton] = useState(false);
  const [togglemenu, settogglemenu] = useState(false);

  const OpenDropdown = () => {
      return  pathname === "/categories" ||
      pathname === "/attributes" ||
      pathname === "/areas" ||
      pathname === "/cities" ||
      pathname === "/countries" ||
      pathname === "/ethnics" ||
      pathname === "/themes" ||
      pathname === "/questions" ||
      pathname === "/wedding-types" ||
      pathname === "/business-attributes" ||
      pathname === "/religions";
  };
  useEffect(() => {
      settogglemenu(OpenDropdown());
  }, [pathname]);


  const [togglemenu2, settogglemenu2] = useState(false);
  const OpenDropdown2 = () => {
      return  pathname === "/tasks" ||
      pathname === "/add-task";
  };
  useEffect(() => {
      settogglemenu2(OpenDropdown2());
  }, [pathname]);

  const [togglemenu3, settogglemenu3] = useState(false);
  const OpenDropdown3 = () => {
      return  pathname === "/admins" ||
      pathname === "/customers" ||
      pathname === "/planners" ||
      pathname === "/vendors"
  };
  useEffect(() => {
      settogglemenu3(OpenDropdown3());
  }, [pathname]);
  return (
    <>
    <div className={toggleButton ? "sidebar close" : "sidebar"}>
        <div class="logo-details">
            <AlignJustify onClick={() => SetToggleButton((prev) => !prev)} stroke='var(--color4)' strokeWidth={1} />
        </div>
        <ul class="nav-links">
        <li className={`${location?.pathname === "/" ? "active" : ""}`}>
            <div class="icon-link">
                <Link to="/">
                    <img src='../assets/images/dashboard-icon.svg' />
                    <span class="link_name">Dashboard</span>
                </Link>
            </div>
        </li>

        {/* {profile?.systemconfig_access?.length > 0 && ( */}
            <li className={`${location?.pathname === "/categories" || location?.pathname === "/attributes" || location?.pathname === "/areas" || location?.pathname === "/cities" 
                || location?.pathname === "/countries" || location?.pathname === "/ethnics" || location?.pathname === "/themes" || location?.pathname === "/questions"
                || location?.pathname === "/religions" ? "active" : ""} ${togglemenu ? "showMenu" : ""}`}>
                <div class="icon-link" onClick={() => settogglemenu(!togglemenu)}>
                    <Link>
                        <img src='../assets/images/system-config-icon.svg' />
                        <span class="link_name">System Config</span>
                    </Link>
                    <span class={`cursor-pointer`}>
                        {togglemenu ? <ChevronUp /> : <ChevronDown />}
                    </span>
                </div>
                <ul class={`sub-menu ${togglemenu && "active"}`}>
                        <li>
                            <Link class={`${location?.pathname === "/categories" ? "active" : ""}`} to="/categories">
                                Categories
                            </Link>
                        </li>
                    
                        <li>
                            <Link class={`${location?.pathname === "/attributes" ? "active" : ""}`} to="/attributes">
                                Attributes
                            </Link>
                        </li>
                    
                        <li>
                            <Link class={`${location?.pathname === "/countries" ? "active" : ""}`} to="/countries">
                                Countries
                            </Link>
                        </li>
                    
                        <li>
                            <Link class={`${location?.pathname === "/cities" ? "active" : ""}`} to="/cities">
                                Cities
                            </Link>
                        </li>
                    
                        <li>
                            <Link class={`${location?.pathname === "/areas" ? "active" : ""}`} to="/areas">
                                Areas
                            </Link>
                        </li>
                    
                        <li>
                            <Link class={`${location?.pathname === "/ethnics" ? "active" : ""}`} to="/ethnics">
                                Ethnicity
                            </Link>
                        </li>
                    
                        <li>
                            <Link class={`${location?.pathname === "/themes" ? "active" : ""}`} to="/themes">
                                Themes
                            </Link>
                        </li>
                    
                        <li>
                            <Link class={`${location?.pathname === "/questions" ? "active" : ""}`} to="/questions">
                                Questions
                            </Link>
                        </li>
                    
                        <li>
                            <Link class={`${location?.pathname === "/religions" ? "active" : ""}`} to="/religions">
                                Religions
                            </Link>
                        </li>
                    
                        <li>
                            <Link class={`${location?.pathname === "/wedding-types" ? "active" : ""}`} to="/wedding-types">
                                Wedding Types
                            </Link>
                        </li>
                    
                        <li>
                            <Link class={`${location?.pathname === "/business-attributes" ? "active" : ""}`} to="/business-attributes">
                                Business Attributes
                            </Link>
                        </li>
                </ul>
            </li>
        {/* )} */}

        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/tasks" || location?.pathname === "/add-task" ? "active" : ""} ${togglemenu2 ? "showMenu" : ""}`}>
                <div class="icon-link" onClick={() => settogglemenu2(!togglemenu2)}>
                    <Link>
                    <img src='../assets/images/task-icon.svg' />
                        <span class="link_name">Tasks</span>
                    </Link>
                    <span class={`cursor-pointer`}>
                        {togglemenu2 ? <ChevronUp /> : <ChevronDown />}
                    </span>
                </div>
                <ul class={`sub-menu ${togglemenu2 && "active"}`}>
                    <li>
                        <Link class={`${location?.pathname === "/tasks" ? "active" : ""}`} to="/tasks">
                            All Tasks
                        </Link>
                    </li>
                    <li>
                        <Link class={`${location?.pathname === "/add-task" ? "active" : ""}`} to="/add-task">
                            Add Task
                        </Link>
                    </li>
                </ul>
            </li>
        )}

            <li className={`${location?.pathname === "/suppliers" || location?.pathname === "/add-supplier" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/suppliers">
                    <img src='../assets/images/vendor-icon.svg' />
                        <span class="link_name">Suppliers</span>
                    </Link>
                </div>
            </li>

            <li className={`${location?.pathname === "/admins" || location?.pathname === "/customers" || location?.pathname === "/planners" || location?.pathname === "/vendors" ? "active" : ""} ${togglemenu3 ? "showMenu" : ""}`}>
                <div class="icon-link" onClick={() => settogglemenu3(!togglemenu3)}>
                    <Link>
                    <img src='../assets/images/users-icon.svg' />
                        <span class="link_name">Users</span>
                    </Link>
                    <span class={`cursor-pointer`}>
                        {togglemenu3 ? <ChevronUp /> : <ChevronDown />}
                    </span>
                </div>
                <ul class={`sub-menu ${togglemenu3 && "active"}`}>
                    <li>
                        <Link class={`${location?.pathname === "/admins" ? "active" : ""}`} to="/admins">
                            Admins
                        </Link>
                    </li>
                    <li>
                        <Link class={`${location?.pathname === "/customers" ? "active" : ""}`} to="/customers">
                            Customers
                        </Link>
                    </li>
                    <li>
                        <Link class={`${location?.pathname === "/planners" ? "active" : ""}`} to="/planners">
                            Planners
                        </Link>
                    </li>
                    <li>
                        <Link class={`${location?.pathname === "/vendors" ? "active" : ""}`} to="/vendors">
                            Vendors
                        </Link>
                    </li>
                </ul>
            </li>



        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/contact-form" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/contact-form">
                    <img src='../assets/images/contact-icon.svg' />
                        <span class="link_name">Contact Queries</span>
                    </Link>
                </div>
            </li>
        )} 

        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/customerinquiries" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/customerinquiries">
                    <img src='../assets/images/users-icon.svg' />
                        <span class="link_name">Customer Inquiries</span>
                    </Link>
                </div>
            </li>
        )}
        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/claim-listings" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/claim-listings">
                    <img src='../assets/images/contact-icon.svg' />
                        <span class="link_name">Claim Listing</span>
                    </Link>
                </div>
            </li>
        )}
        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/report-listings" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/report-listings">
                    <img src='../assets/images/contact-icon.svg' />
                        <span class="link_name">Report Listing</span>
                    </Link>
                </div>
            </li>
        )}

        </ul>
    </div>
  </>
  )
}

export default SideBar