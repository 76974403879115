import { Eye, EyeOff } from 'lucide-react';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../store/apiSlice';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSecureEntry, setisSecureEntry] = useState(true)
    const [load, setload] = useState(false)
  
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setload(true)
      try {
        const payload = { 
          email, 
          password, 
          role: "admin",
          deviceType: "web",
          platform: "shadiTayari"
        }
        await dispatch(signIn(payload)).unwrap();
        navigate('/');
        setload(false)
    } catch (rejectedValueOrSerializedError) {
        setload(false)
        console.log(rejectedValueOrSerializedError);
      }
    };

    document.title = "Login - Shadi Tayari Admin"
  
  return (
    <>
    <section class="vh-100 authentication-bg">
    <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card wf-shadow radius-10 border-0">
            <div class="card-body p-5 text-center">
                <img alt="Shadi Tayari" className="mx-auto d-block auth-logo mb-5" width="320" src={"../assets/images/logo.svg"} />
                <form onSubmit={handleSubmit}>
                <div class="form-floating wf-input mt-3 mb-3">
                <input type="email" class="form-control" id="email" placeholder="Email Address"  onChange={(e) => setEmail(e.target.value)} autocomplete="email" required />
                <label for="email">Email address</label>
                </div>
                <div class="form-floating wf-input mb-5">
                <input class="form-control" id="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} type={isSecureEntry ? "password" : "text"} autocomplete="password" required />
                <div className='eye-password' onClick={() => setisSecureEntry((prev) => !prev)}>{isSecureEntry ? <Eye /> : <EyeOff />}</div>
                <label for="password">Password</label>
                </div>
                <button class="btn wf-btn wf-purple-btn w-100" type="submit">
                {load ? <div className="loading-dots"><div className="loading-dot"></div><div className="loading-dot"></div><div className="loading-dot"></div></div> : "Login"}
                </button>
                </form>
            </div>
            </div>
        </div>
        </div>
    </div>
    </section>
    </>
  )
}

export default Login