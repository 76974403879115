import axios from "axios";
import toast from "react-hot-toast";
import { addArea, addCity, addCountry, deleteArea, deleteCity, deleteCountry, editArea, editCity, editCountry, getCityList, signIn } from "./apiSlice";


export const extraReducers = (builder) => {
  builder

    // signIn
    .addCase(signIn.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(signIn.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.user = action.payload.data;
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      // axios.defaults.headers.common['Authorization'] = action.payload.data.token;
      axios.defaults.headers.common['Authorization'] = action.payload.data.token ? `Bearer ${action.payload.data.token}` : '';
      toast.success(action.payload.meta.message);
    })
    .addCase(signIn.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })

    // addArea
    .addCase(addArea.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addArea.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.meta.message);
    })
    .addCase(addArea.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })

    // editArea
    .addCase(editArea.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editArea.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.meta.message);
    })
    .addCase(editArea.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })

    // deleteArea
    .addCase(deleteArea.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(deleteArea.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.meta.message);
    })
    .addCase(deleteArea.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })

    // getCityList
    .addCase(getCityList.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getCityList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      // toast.success(action.payload.meta.message);
    })
    .addCase(getCityList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })

    // addCity
    .addCase(addCity.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addCity.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.meta.message);
    })
    .addCase(addCity.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })

    // editCity
    .addCase(editCity.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editCity.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.meta.message);
    })
    .addCase(editCity.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })

    // deleteCity
    .addCase(deleteCity.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(deleteCity.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.meta.message);
    })
    .addCase(deleteCity.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })

    // addCountry
    .addCase(addCountry.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addCountry.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.meta.message);
    })
    .addCase(addCountry.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })

    // editCountry
    .addCase(editCountry.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editCountry.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.meta.message);
    })
    .addCase(editCountry.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })

    // deleteCountry
    .addCase(deleteCountry.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(deleteCountry.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.meta.message);
    })
    .addCase(deleteCountry.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.meta.message;
      toast.error(action.payload.meta.message);
    })
};