import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Breadcrumb from '../components/elements/Breadcrumb';
import { Pencil, Trash2 } from 'lucide-react';
import Table from '../components/elements/Table';
import CustomModal from '../components/elements/Modal';
import DeleteModal from '../components/DeleteModal';
import Title from '../components/elements/Title';
import Button from '../components/elements/Button';
import { deleteQuestion, getQuestionList } from '../store/apiSlice';
import { Link, useNavigate } from 'react-router-dom';
import { userTypes } from '../utils/Helper';

const Question = () => {
    document.title = "Questions - Shadi Tayari Admin"
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [questions, setquestions] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);

  const viewModal = async (item, type) => {
    setIsOpen(true);
    setId(item);
    setModalType(type);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const questionDelete = async (id) => {
    try {
      await dispatch(deleteQuestion(id)).unwrap();
      setIsOpen(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Questions = async () => {
    try {
      setload(true)
      setquestions(null);
      const response = await dispatch(getQuestionList()).unwrap();
      setquestions(response?.data);
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Questions();
  }, []);

  console.log(questions, "questions");
  

  return (
    <>
    <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <DeleteModal closeModal={closeModal} onClick={() => questionDelete(id)} label="Question" />
    </CustomModal>


    <div className="wf-city-list-page px-md-5 pt-3 px-3 pb-5">
    <Breadcrumb items={[{ label: 'Menu',}, { label: 'System Config',},{ label: 'Questions', active: true }]}/>
      <div className="row gy-3 mb-4 align-items-center mb-2">
        <div className="col-md-3 col-6 align-self-center order-1">
          <Title>Questions</Title>
        </div>
        <div className="col-md-6 order-md-2 order-3">
        </div>
        <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
          <Button variant="purple" size="small" onClick={()=> navigate("/add-question")}>Add Question</Button>
        </div>
      </div>
      <div className="radius-20 white-bg wf-shadow p-3">
        <p className="font-size-20 pink-color">Questions: {questions?.length}</p>
        <Table>
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Heading</th>
            <th scope="col">Question Customer</th>
            <th scope="col">Question Vendor</th>
            <th scope="col">Category</th>
            <th scope="col">Role</th>
            <th scope="col">Type</th>
            <th style={{width:'100px'}} scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={10}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : questions?.map((item,i)=> (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{item?.headingText}</td>
            <td>{item?.questionCustomer}</td>
            <td>{item?.questionVendor}</td>
            <td>{item?.categoryId?.name}</td>
            <td className="text-capitalize">{item?.roles?.join(", ")}</td>
            <td className="text-capitalize">{item?.type}</td>
            <td>
              <div className="d-flex align-items-center gap-2">
                  <Link to={`/edit-question?id=${item?._id}`} onClick={() => viewModal(item, "edit")} className={`wf-action-icons`}><Pencil width="18" /></Link>
                  <div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons`}><Trash2 width="18" /></div>
              </div>
            </td>
            </tr>))}
        </tbody>
        </Table>
      </div>
    </div>
    </>
  )
}

export default Question